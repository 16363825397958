.tempSlideContainer {
  position: absolute;
  top: -100px;
  padding-top: 122px;
  background-image: linear-gradient(transparent, white 20%, white);
}

.tempSlideCard {
  width: 250px;
  min-height: 400px;
  padding: 75px 30px 0px;
}

.tempSlideCard .box {
  border-radius: 20px;
  position: relative;
  height: 350px;
}

.miniImageCard {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
}

.miniImageCard img {
  object-fit: cover;
}

.card-detail {
  padding: 100px 10px 0px;
}

.slideButton {
  width: 100px;
  height: 50px;
  background-color: var(--sanatanSewaNyas-base);
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
}
.title-card-heading{
  font-weight: 700;
  font-size: 38px;
}
@media (max-width: 1399px) {
  .tempSlideCard .box {
    height: 400px;
  }
}
@media (max-width: 1199px) {
  .tempSlideCard .box {
    height: 440px;
  }
}
@media (max-width: 1024px) {
  .tempSlideCard .box {
    height: 350px;
  }
}
@media (max-width: 991px) {
  .tempSlideCard .box {
    height: 440px;
  }
}


.bg-lightgray {
 /* Adjust the color code to achieve the desired slightly darker grey */
  border-radius: 10px;
}

.bg-lightgray:hover {
  background-color: #e9ecef; /* Adjust the color code to achieve the desired slightly darker grey */
 
}

.drop_bg{
  box-shadow: 2px 10px 15px -5px rgba(0,0,0,0.25);
  border-radius: 8px;
 background-color: #ffffff;
 
}



